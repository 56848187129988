import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Train_ad = ({ data, location }) => (
  <Layout>
    <SEO 
      pagetitle="車内広告"
      pagedesc="車内広告は主要なものとして車両内の中央通路に位置する中吊りポスター広告。そして、車内の網棚上方部分に掲出するまど上ポスター広告。
      双方ともこちらは座っている人も立っている人も見る事が出来て、駅間移動中はずっと見ることもできるので訴求内容が多少多めでも詳細まで読ませることも可能な広告です。"
      pagepath={location.pathname}
      pageimg={data.train_ad.childImageSharp.original.src}
      pageimgw={data.train_ad.childImageSharp.original.width}
      pageimgh={data.train_ad.childImageSharp.original.height}
    />
    <section className="area">
    <div className="container">
      <h2 className="bar">車内広告</h2>
      <br />
      <figure>
        <GatsbyImage image={data.train_ad.childImageSharp.gatsbyImageData} alt="" />
      </figure>
      <br />
      <p className="article">
      <span className="article-sub">車両広告について</span><br />
      ・車内では、主要なものとして車内の中央通路に位置する中吊りポスター広告。そして、車内の網棚上方部分に掲出するまど上ポスター広告。
双方ともこちらは座っている人も立っている人も見る事が出来て、次の駅までの間の移動中はずっと見ることもできるので記事内容が多少多めでも
詳細まで読ませることも可能な広告です。他では、車内唯一の動画広告であるトレインチャンネルも視認性抜群で印象に残りやすい広告です。<br /><br />
さらに集中的に展開できる商品が、ご希望の１編成車両に中吊りポスターを集中的に展開する中吊り貸切ポスター広告があります。こちらは、
１編成なので通常の中吊りポスターの様に全ての車両に満遍なく掲出されるというわけではないのでレア感は高いですが、
見つけた時は中吊り一式が同じテーマで埋め尽くしですのでシェアしたくなる程のインパクトあります。
そして、例えば新コスメや美容に関する商品など女性のみに訴求したいという場合は、女性専用がある車両にのみ中吊りを集中的に展開できる
女性専用車中吊りジャック広告というものがあります。完全にターゲットを絞った広告なので、無駄なく高い効果が期待できます。<br /><br />
新橋から発着のゆりかもめ線は観光シーズンや東京ビッグサイトでのイベント開催時は利用者が大幅に増加するので、タイミング合わせてゆりかもめ
車内広告を展開するのも現地で開催されるイベントや商業施設への誘引に役立ちます。<br /><br />
また、年々沿線利用者が増加傾向にあるつくばエクスプレスも、平日は通勤通学、休日は観光やショッピングなどで都心に向かって利用者が増えるので、
シーズンなどに合わせて訴求したい商品や情報を車内網棚の上に広告展開するまど上ポスター広告や車両内の中央通路上から吊るして広告展開する中吊り
ポスター広告などをの車内広告使って訴求すると効果的です。１編成車両に中吊りポスターを貸切掲出する中吊り貸切ポスター広告もあります。
こちらつくばエクスプレス利用者に集中PRする商品で、１週間単位から販売しております。<br /><br />
なんと言っても車内広告の特徴としては、複数の駅をターゲットに広告展開したい場合、各駅ごとに広告展開すると費用的にもかなりの金額になりますが、
車内広告であればターゲット駅と沿線上の他駅利用者へも訴求が同時にできてしまうというのが大きな特徴です。
また、ほぼ毎日利用するという働く年代の男性で約７割弱まで、女性では約６割前後の利用者が、車内の広告に接触しているという統計が出ていますので
反復して広告内容に接触させられる事ができ、高い費用対効果が期待できる広告媒体です。<br /><br />
『自然と目に入る』『広告が目立つ』というメディアイメージになっており、
中吊りポスター広告やまど上ポスター広告などの主要な広告媒体ではそれが５割弱まで達しています。
また、週に１回以上の接触割合は男性で約６５％、女性で５３％になっています。<br />
出典：Jeki首都圏移動者調査<br /><br />
また、広告到達率・商品興味関心度の項目で
『見た（見たような気がする）』『（非常に）興味を持った』と言う人の割合が５割近くまで達しています。<br />
出典：交通広告共通指標策定調査<br /><br />
車両によっては２日単位からの短期商品もありますが、大半は７日間単位と１ヶ月単位で広告展開が主になります。
広告掲出開始の１週間前にデザイン審査が完了した広告物を納品頂きたいので、それに合わせた任意のタイミングでご相談頂ければと思います。



      </p> 
    </div>
    </section>
    <section className="Advertising">
    <div className="container">
    <div className="block">
        <div className="ad-detail">
        <Link to={`/cat/trainAd_cat/`}>
        <button className="button-first">車内広告一覧へ</button>
        </Link>
        </div>
        <div className="ad-detail">
        <Link to={`/form`}>
        <button className="button-second">お問い合わせ</button>
        </Link>
        </div>
    </div>
    </div>
    </section>
  </Layout>
)
  
export const query = graphql`
  query {
    train_ad: file(relativePath: {eq: "topshanai.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
        original {
          src
          height
          width
        }
      }
    }
  }
`

export default Train_ad